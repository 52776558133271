
import {
  gbsHelpMapActions,
  gbsHelpMapGetters,
  gbsHelpMapMutations,
  gbsHelpMapState
} from "@/store/modules/gbshelp";
import Vue from "vue";
import { tableColumnsListGbsHelp } from "./GbsHelpTable";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { companiesMapActions } from "@/store/modules/companies";
import * as types from "@/store/mutation-types";

interface ICompanyCompanyData {
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  companyId: string;
  tableRowActions: any[];
  loadingText: string;
}
export default Vue.extend({
  name: "listPPCs",
  data(): ICompanyCompanyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableColumns: tableColumnsListGbsHelp,
      loading: false,
      companyId: "",
      tableRowActions: [],
      tableActions: ["Upload PPC", "All activities"].sort(),
      loadingText: ""
    };
  },
  components: {
    GbsHelpUploader: () => import("./GbsHelpUploader.vue"),
    NoResultsFound
  },
  methods: {
    ...companiesMapActions(["getCompanies"]),
    ...gbsHelpMapActions(["getGbsHelps", "deleteGbsHelp"]),
    ...gbsHelpMapMutations([types.SET_STATE, types.SET_EDIT, types.SET_ITEM]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "createPPC":
          this.$router.push(`/admin/ppc/create`);
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading PPCs. Please wait...";
        this.$emit("scrollTop");
        if (!queryObject.__sort) {
          queryObject["__sort"] = "county";
        }
        await this.getGbsHelps({
          query: { ...queryObject }
        });

        await this.getCompanies({});
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/ppc/${_id}`).catch(() => {});
    },
    async createCallback(): Promise<any> {
      this.$router.push(`/admin/companies/create`);
    },
    // getOperationsTemplate(): any {
    //   return CompanyTableOperations;
    // },
    //
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "duplicate":
          //do nothing
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/admin/companies/${data._id}`);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    // delete company from here

    async deleteOneCompany(): Promise<void> {
      try {
        this.isLoading = true;

        await this.deleteGbsHelp(this.companyId);

        this.$appNotifySuccess("Company Successfully Deleted");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
      }
    },
    //delete modal
    async deleteCallback(companyId: any): Promise<void> {
      try {
        this.companyId = companyId;
        this.$modal.show("companiesDeleteModal");
      } catch (error) {
        // delete disabled
      }
    },
    uploadCountiesActionHandler(): void {
      this.$modal.show("ppcUploaderModal");
    },
    tableActionHandler(action: string) {
      switch (action) {
        case "Upload PPC":
          return this.uploadCountiesActionHandler();
        case "All activities":
          this.$router.push(`/admin/activities/ppc`).catch(() => {});
      }
    },
    async uploadSuccess(): Promise<void> {
      this.$appNotifySuccess("Uploaded ppc");
      await this.getGbsHelps({
        query: {}
      });
    }
  },
  computed: {
    ...gbsHelpMapGetters(["gbsHelp", "getTotalRecords", "getGbsHelpLinks"]),
    ...gbsHelpMapState(["makingApiRequest"])
  }
});
